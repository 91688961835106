<template>
  <section v-if="Object.keys(pricing).length" id="pricing-plan">
    <!-- title text and switch button -->
    <div class="text-center">
      <h1 class="mt-5">
        Цены
      </h1>
      <p class="mb-2 pb-75">
        Все тарифы включают в себя инструменты увеличивающие конверсию вашего
        продукта. Выберите план, который подходит именно вам.
      </p>
      <!-- <div class="d-flex align-items-center justify-content-center mb-5 pb-50">
        <h6 class="mr-1 mb-0">
          Месяц
        </h6>
        <b-form-checkbox
          id="priceSwitch"
          v-model="status"
          name="price-switch"
          value="annually"
          unchecked-value="monthly"
          switch
          @input="tooglePlan"
        />
        <h6 class="ml-50 mb-0">
          Пол года
        </h6>
      </div> -->
    </div>
    <!--/ title text and switch button -->

    <!-- pricing plan cards -->
    <b-row class="pricing-card">
      <b-col offset-sm-2 sm="10" md="12" offset-lg="2" lg="10" class="mx-auto">
        <b-row style="min-height: 500px;">
          <b-col md="4">
            <b-card align="center" class="h-100">
              <!-- img -->
              <b-img
                v-if="pricing.startPlan.img"
                :src="pricing.startPlan.img"
                class="mb-2"
                alt="enterprise svg img"
              />
              <!--/ img -->
              <h3>{{ pricing.startPlan.title }}</h3>
              <b-card-text>{{ pricing.startPlan.subtitle }}</b-card-text>

              <!-- annual plan -->
              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <sup class="font-medium-1 font-weight-bold text-primary"
                    >₽</sup
                  >
                  <span
                    class="pricing-basic-value font-weight-bolder text-primary"
                    >{{
                      monthlyPlanShow
                        ? pricing.startPlan.monthlyPrice
                        : pricing.startPlan.yearlyPlan.perMonth
                    }}</span
                  >
                  <sub
                    class="pricing-duration text-body font-medium-1 font-weight-bold"
                    >/месяц</sub
                  >
                </div>
                <small
                  v-show="!monthlyPlanShow"
                  class="annual-pricing text-muted"
                  >Руб {{ pricing.startPlan.yearlyPlan.totalAnual }} / 6
                  месяцев</small
                >
              </div>
              <!--/ annual plan -->

              <!-- plan benefit -->
              <b-list-group
                v-for="(data, index) in pricing.startPlan.planBenefits"
                :key="index"
                class="list-group-circle text-left"
              >
                <b-list-group-item>
                  {{ data }}
                </b-list-group-item>
              </b-list-group>
              <!--/ plan benefit -->

              <!-- buttons -->
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                block
                class="mt-2 checkout-button"
                variant="outline-primary"
                @click="checkout('startPlan')"
              >
                Улучшить
              </b-button>
            </b-card>
          </b-col>
          <b-col md="4">
            <b-card class="popular h-100" align="center">
              <div
                v-show="pricing.businessPlan.popular"
                class="pricing-badge text-right"
              >
                <b-badge variant="light-primary" pill>
                  Популярный
                </b-badge>
              </div>
              <b-img
                v-if="pricing.businessPlan.img"
                :src="pricing.businessPlan.img"
                class="mb-1"
                alt="svg img"
              />
              <h3>{{ pricing.businessPlan.title }}</h3>
              <b-card-text>{{ pricing.businessPlan.subtitle }}</b-card-text>
              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <sup class="font-medium-1 font-weight-bold text-primary"
                    >₽</sup
                  >
                  <span
                    class="pricing-basic-value font-weight-bolder text-primary"
                    >{{
                      monthlyPlanShow
                        ? pricing.businessPlan.monthlyPrice
                        : pricing.businessPlan.yearlyPlan.perMonth
                    }}</span
                  >
                  <sub
                    class="pricing-duration text-body font-medium-1 font-weight-bold"
                    >/месяц</sub
                  >
                </div>
                <small
                  v-show="!monthlyPlanShow"
                  class="annual-pricing text-muted"
                  >Руб {{ pricing.businessPlan.yearlyPlan.totalAnual }} / 6
                  месяцев</small
                >
              </div>
              <b-list-group class="list-group-circle text-left">
                <b-list-group-item
                  v-for="(data, index) in pricing.businessPlan.planBenefits"
                  :key="index"
                >
                  {{ data }}
                </b-list-group-item>
              </b-list-group>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                block
                class="mt-2 checkout-button"
                variant="primary"
                @click="checkout('businessPlan')"
              >
                Улучшить
              </b-button>
            </b-card>
          </b-col>
          <b-col md="4">
            <b-card align="center" class="h-100">
              <b-img
                v-if="pricing.enterprisePlan.img"
                :src="pricing.enterprisePlan.img"
                class="mb-2"
                alt="enterprise svg img"
              />
              <h3>{{ pricing.enterprisePlan.title }}</h3>
              <b-card-text>{{ pricing.enterprisePlan.subtitle }}</b-card-text>

              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <sup class="font-medium-1 font-weight-bold text-primary"
                    >₽</sup
                  >
                  <span
                    class="pricing-basic-value font-weight-bolder text-primary"
                    >{{
                      monthlyPlanShow
                        ? pricing.enterprisePlan.monthlyPrice
                        : pricing.enterprisePlan.yearlyPlan.perMonth
                    }}</span
                  >
                  <sub
                    class="pricing-duration text-body font-medium-1 font-weight-bold"
                    >/месяц</sub
                  >
                </div>
                <small
                  v-show="!monthlyPlanShow"
                  class="annual-pricing text-muted"
                  >Руб {{ pricing.enterprisePlan.yearlyPlan.totalAnual }} / 6
                  месяцев</small
                >
              </div>
              <b-list-group
                v-for="(data, index) in pricing.enterprisePlan.planBenefits"
                :key="index"
                class="list-group-circle text-left"
              >
                <b-list-group-item>
                  {{ data }}
                </b-list-group-item>
              </b-list-group>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                block
                class="mt-2 checkout-button"
                variant="outline-primary"
                @click="checkout('enterprisePlan')"
              >
                Улучшить
              </b-button>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <div class="text-center">
      <h1 class="mt-5">
        История платежей
      </h1>
    </div>
    <b-row>
      <b-col offset-sm-2 sm="10" md="12" offset-lg="2" lg="10" class="mx-auto">
        <payments-table :payments="getPayments.data" />
      </b-col>
    </b-row>
    <!-- <div class="pricing-faq">
      <h3 class="text-center">
        FAQ's
      </h3>
      <p class="text-center">
        Let us help answer the most common questions.
      </p>
      <b-row class="py-2">
        <b-col lg="10" offset-lg="2" class="mx-auto">
          <app-collapse accordion type="margin">
            <app-collapse-item
              v-for="(data, index) in pricing.qandA"
              :key="index"
              :title="data.question"
            >
              {{ data.ans }}
            </app-collapse-item>
          </app-collapse>
        </b-col>
      </b-row>
    </div> -->
    <!--/ pricing faq -->
  </section>
</template>

<script>
import {
  BFormCheckbox,
  BRow,
  BCol,
  BCard,
  BImg,
  BCardText,
  BListGroup,
  BListGroupItem,
  BButton,
  BBadge,
} from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import Ripple from "vue-ripple-directive";
import { mapActions, mapGetters } from "vuex";
import { cloudPaymentApi } from "@/main";
import PaymentsTable from "./PaymentsTable.vue";

/* eslint-disable global-require */
export default {
  components: {
    BFormCheckbox,
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BBadge,
    BImg,
    AppCollapseItem,
    AppCollapse,
    PaymentsTable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      status: "monthly",
      monthlyPlanShow: true,
      pricing: {
        startPlan: {
          img: "/img/Pot3.a02fefbf.svg",
          monthlyPrice: 990,
          popular: false,
          subtitle: "3 видео-виджета",
          title: "Start",
          // planBenefits: ["3 видео-виджета"],
          yearlyPlan: {
            perMonth: 990,
            totalAnual: 5,
          },
        },
        businessPlan: {
          img: "/img/Pot3.a02fefbf.svg",
          monthlyPrice: 2490,
          popular: true,
          subtitle: "10 видео-виджетов",
          title: "Business",
          // planBenefits: ["10 видео-виджетов"],
          yearlyPlan: {
            perMonth: 2490,
            totalAnual: 10,
          },
        },
        enterprisePlan: {
          img: "/img/Pot3.a02fefbf.svg",
          monthlyPrice: 4990,
          popular: false,
          subtitle: "Без ограничений",
          title: "Enterprise",
          // planBenefits: ["Без ограничений"],
          yearlyPlan: {
            perMonth: 4990,
            totalAnual: 15,
          },
        },
        qandA: [
          {
            ans:
              "Pastry pudding cookie toffee bonbon jujubes jujubes powder topping. Jelly beans gummi bears sweet roll bonbon muffin liquorice. Wafer lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie biscuit marzipan. Jujubes donut marzipan chocolate bar. Jujubes sugar plum jelly beans tiramisu icing cheesecake.",
            question: "Does my subscription automatically renew?",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("user", ["getTariffTill", "getUserData", "getPayments"]),
  },
  metaInfo() {
    return {
      title: "moovee - Цены",
    };
  },
  methods: {
    ...mapActions("user", [
      "requestUserData",
      "updatePayment",
      "requestPayments",
    ]),
    tooglePlan() {
      if (this.status === "monthly") {
        this.monthlyPlanShow = true;
      } else {
        this.monthlyPlanShow = false;
      }
    },
    checkout(plan) {
      const pricing = this.pricing[plan];
      const price =
        this.status === "monthly"
          ? pricing.monthlyPrice
          : pricing.yearlyPlan.totalAnual;
      var widget = new cp.CloudPayments();
      widget.pay(
        "auth", // или 'charge'
        {
          //options
          publicId: cloudPaymentApi, //id из личного кабинета
          description: `Оплата плана moovee.io ${pricing.title} ${
            this.status === "monthly" ? "1 Месяц" : "6 Месяцев"
          }`, //назначение
          amount: price, //сумма
          currency: "RUB", //валюта
          accountId: this.getUserData.email,
          email: this.getUserData.email,
          skin: "mini", //дизайн виджета (необязательно)
          data: {
            myProp: "myProp value",
          },
        },
        {
          onComplete: function(paymentResult, options) {
            if (paymentResult.success) {
              this.updatePayment({
                tariff: pricing.title,
                status: this.status,
                currentTill: this.getTariffTill,
              }).then(() => {
                this.$router.push({ name: "pages-payment-success" });
              });
            }
          }.bind(this),
        }
      );
    },
  },
  mounted() {
    this.requestUserData();
    this.requestPayments();
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-pricing.scss";
.checkout-button {
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  position: absolute;
  bottom: 0px;
}
@import "@core/scss/vue/libs/vue-good-table.scss";
.projects-table tr:hover {
  background-color: #f8f8f8;
}
</style>

<template>
  <vue-good-table
    :columns="columns"
    :rows="payments"
    :rtl="direction"
    :bordered="false"
    class="projects-table"
    styleClass="vgt-table"
  >
    <div slot="emptystate" class="text-center">
      Нет платежей
    </div>
    <template slot="table-row" slot-scope="props">
      <span v-if="props.column.field === 'createdAt'" class="text-nowrap">
        <span class="text-nowrap">
          {{
            new Date(props.row.createdAt.seconds * 1000).toLocaleDateString() +
              " " +
              props.row.createdAt.toDate().toLocaleTimeString("ru-RU")
          }}
        </span>
      </span>
      <span v-else-if="props.column.field === 'tariff'" class="text-nowrap">
        <span class="text-nowrap">
          {{ props.row.tariff }}
        </span>
      </span>
      <span v-else-if="props.column.field === 'duration'" class="text-nowrap ">
        <div class="text-nowrap">
          {{ props.row.duration == "monthly" ? "Месяц" : "Год" }}
        </div>
      </span>
    </template>
  </vue-good-table>
</template>

<script>
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    VueGoodTable,
  },
  directives: {
    Ripple,
  },
  props: ["payments"],
  data() {
    return {
      dir: false,
      rows: [],
      columns: [
        {
          label: "Дата Оплаты",
          field: "createdAt",
        },
        {
          label: "Тарифный план",
          field: "tariff",
        },
        {
          label: "Подписка на",
          field: "duration",
        },
      ],
    };
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
};
</script>
